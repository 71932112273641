import React from "react";
import { Route, withRouter, RouteComponentProps } from "react-router-dom";

interface RedirectProps extends RouteComponentProps<any> {
  host: string;
}

const Redirect: React.FC<RedirectProps> = (props: RedirectProps) => {
  const { ...routeProps } = props;

  const link = `${props.host}${props.location.pathname}${props.location.search}`;
  return (
    <Route
      {...routeProps}
      render={() => {
        window.location.replace(link);
        return null;
      }}
    />
  );
};

export default withRouter(Redirect);
