import React from "react";
import { Shell } from "@ofqual/portal-client-shell";
import Authentication from "@ofqual/portal-auth";
import { moduleDefinition as ComplaintsModuleDefinition } from "@ofqual/portal-complaints-module";
import { moduleDefinition as UsersModuleDefinition } from "@ofqual/portal-users";
import { moduleDefinition as NotificationsModuleDefinition } from "@ofqual/portal-notifications";
import { moduleDefinition as OrganisationsModuleDefinition } from "@ofqual/portal-organisations";
import { moduleDefinition as SVRModuleDefinition } from "@ofqual/portal-svr";
import { moduleDefinition as ActivtyFeedModuleDefinition } from "@ofqual/portal-activity-feed";
import { moduleDefinition as LinkedRecordsModuleDefinition } from "@ofqual/portal-linked-records";
import { moduleDefinition as OrganisationScopeModuleDefinition } from "@ofqual/portal-organisations-scope";
import { moduleDefinition as NewsModuleDefinition } from "@ofqual/portal-news";
import { moduleDefinition as CommsModuleDefinition } from "@ofqual/portal-comms";
import { moduleDefinition as CaoModuleDefinition } from "@ofqual/portal-comms-cao";
import { moduleDefinition as CtrModuleDefinition } from "@ofqual/portal-comms-ctr";
import { moduleDefinition as HomeModuleDefinition } from "@ofqual/portal-home";
import { articlesModuleDefinition as ArticlesModuleDefinition } from "@ofqual/portal-home";
import { moduleDefinition as UnitsModuleDefinition } from "@ofqual/portal-units";
import { moduleDefinition as QualModuleDefinition } from "@ofqual/portal-qualifications";
//import { moduleDefinition as SupportModuleDefinition } from "@ofqual/portal-support";
import { moduleDefinition as supportButtonDefinition } from "@ofqual/portal-support-button";
import { moduleDefinition as ApqModuleDefinition } from "@ofqual/portal-apq";
import { moduleDefinition as ReportsModuleDefinition } from "@ofqual/portal-reports";
import { moduleDefinition as PublicEnquiryModuleDefinition } from "@ofqual/portal-public-enquiry";
import { moduleDefinition as FoiModuleDefinition } from "@ofqual/portal-foi";
import { moduleDefinition as DataPortalModuleDefinition } from "@ofqual/portal-data-portal";
import { moduleDefinition as Soc2022ModuleDefinition } from "@ofqual/portal-soc-2022"
import { moduleDefinition as SarsModuleDefinition } from "@ofqual/portal-sars";
import { moduleDefinition as LcmModuleDefinition } from "@ofqual/portal-lcm";
import { moduleDefinition as SpecialistModuleDefinition } from "@ofqual/portal-experts-apply";
import { moduleDefinition as Soc2023ModuleDefinition } from "@ofqual/portal-soc-2023";
import { moduleDefinition as ExpertCommissionsDefinition } from "@ofqual/portal-commissions";
import { moduleDefinition as SocV2ModuleDefinition } from "@ofqual/portal-soc-v2";

import {
  Home,
  MailOutline,
  SmsFailed,
  Domain,
  School,
  LibraryBooks,
  GridOn,
  InsertChart,
  Face,
  FolderShared,
  PieChart,
  Link,
  MenuBook,
  ContactSupport,
  Work,
  AssignmentInd
} from "@material-ui/icons";
import Redirect from "./Redirect";
import { MenuItemProp } from "@ofqual/portal-client-shell/dist/module/ModuleProps";

function App() {
  // todo: move these somewhere else?
  const viewRole: number[] = [3];
  const aoRoles: number[] = [20, 19, 18, 14, 10, 9, 8, 7, 26];

  const hasRole = (usersRoles: number[], rolesToCheck: number[]) =>
    usersRoles.some((usersRole: any) => rolesToCheck.includes(usersRole));

  const menuItems: MenuItemProp[] = [
    {
      menuTitle: "Home",
      menuIcon: Home,
      pathOrUrl: "/",
      exactPathMatch: true,
      module: HomeModuleDefinition,
      visibility: () => true,
    },
    {
      menuTitle: "NOT_NEEDED",
      menuIcon: Home,
      pathOrUrl: "/articles/:id",
      module: ArticlesModuleDefinition,
      visibility: () => false,
      loadWhenInvisible: () => true,
    },
    {
      // Old Inbox
      menuTitle: (user) =>
        hasRole(user.roles, aoRoles) ? "My Inbox" : "Inbox",
      menuIcon: MailOutline,
      pathOrUrl: "/inbox",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: () => true,
      experimentalFeatureHiddenName: "InboxModule",
    },
    {
      menuTitle: "NOT_NEEDED",
      menuIcon: MailOutline,
      pathOrUrl: "/communications/contact-ao",
      module: CaoModuleDefinition,
      visibility: (user) => false,
      loadWhenInvisible: () => true,
      experimentalFeatureVisibleName: "InboxModule",
    },
    {
      menuTitle: (user) =>
        hasRole(user.roles, aoRoles) ? "My Inbox" : "Inbox",
      menuIcon: MailOutline,
      pathOrUrl: "/communications/contact-the-regulator",
      module: CtrModuleDefinition,
      visibility: () => true,
      loadWhenInvisible: () => true,
      experimentalFeatureVisibleName: "InboxModule",
    },
    {
      menuTitle: "NOT_NEEDED",
      menuIcon: MailOutline,
      pathOrUrl: "/communications/event-notifications",
      module: CommsModuleDefinition,
      visibility: (user) => false,
      loadWhenInvisible: () => true,
      experimentalFeatureVisibleName: "InboxModule",
    },
    {
      menuTitle: (user) => (hasRole(user.roles, aoRoles) ? "My APQs" : "APQs"),
      menuIcon: MenuBook,
      pathOrUrl: "/assessment-plan-query",
      module: ApqModuleDefinition,
      visibility: (user) => [1, 3].includes(user.userType), // AO and Ofqual
      loadWhenInvisible: () => false,
      experimentalFeatureVisibleName: "ApqInbox",
    },

    {
      menuTitle: "News",
      menuIcon: LibraryBooks,
      pathOrUrl: "/news",
      module: NewsModuleDefinition,
      visibility: NewsModuleDefinition.visibleOnNavigationBar,
      loadWhenInvisible: () => true,
    },
    {
      menuTitle: "NOT_NEEDED",
      menuIcon: Domain,
      pathOrUrl: "/organisations/:itemId/scope/applications",
      module: OrganisationScopeModuleDefinition,
      visibility: (user) => false,
      loadWhenInvisible: () => true,
    },
    {
      menuTitle: (user) =>
        hasRole(user.roles, aoRoles) ? "My Organisation" : "Organisations",
      menuIcon: Domain,
      pathOrUrl: "/organisations",
      module: OrganisationsModuleDefinition,
      visibility: (user) => true,
    },
    {
      // Old QUals
      menuTitle: (user) =>
        hasRole(user.roles, aoRoles) ? "My Qualifications" : "Qualifications",
      menuIcon: School,
      pathOrUrl: "/qualifications",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: () => true,
      experimentalFeatureHiddenName: "QualsModule",
    },
    {
      menuTitle: (user) =>
        hasRole(user.roles, aoRoles) ? "My Qualifications" : "Qualifications",
      menuIcon: School,
      pathOrUrl: "/qualifications",
      module: QualModuleDefinition,
      visibility: (_) => true,
      experimentalFeatureVisibleName: "QualsModule",
    },
    {
      // Old QUals
      menuTitle: (user) =>
        hasRole(user.roles, aoRoles) ? "My Units" : "Units",
      menuIcon: GridOn,
      pathOrUrl: "/units",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: () => true,
      experimentalFeatureHiddenName: "UnitsModule",
    },
    {
      menuTitle: (user) =>
        hasRole(user.roles, aoRoles) ? "My Units" : "Units",
      menuIcon: GridOn,
      pathOrUrl: "/units",
      module: UnitsModuleDefinition,
      visibility: (_) => true,
      experimentalFeatureVisibleName: "UnitsModule",
    },
    {
      menuTitle: "Data Portal",
      menuIcon: InsertChart,
      pathOrUrl: "/submissions",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: (_) => true,
      experimentalFeatureHiddenName: "DataPortalModule",
    },
    {
      menuTitle: "Data Portal",
      menuIcon: InsertChart,
      pathOrUrl: "/submissions",
      module: DataPortalModuleDefinition,
      visibility: (_) => true,
      experimentalFeatureVisibleName: "DataPortalModule",
    },
    {
      menuTitle: "Users",
      menuIcon: Face,
      pathOrUrl: "/users",
      module: UsersModuleDefinition,
      visibility: UsersModuleDefinition.visibleOnNavigationBar,
    },
    {
      menuTitle: "Experts",
      menuIcon: FolderShared,
      pathOrUrl: "/experts",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: (user) => hasRole(user.roles, [...viewRole, 21, 22, 23]),
    },
    {
      menuTitle: (user) =>
        hasRole(user.roles, aoRoles) ? "My Reports" : "Reports",
      menuIcon: PieChart,
      pathOrUrl: "/reports",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: (user) => hasRole(user.roles, aoRoles),
      experimentalFeatureHiddenName: "portal-reports",
    },
    {
      menuTitle: (user) =>
        hasRole(user.roles, aoRoles) ? "My Reports" : "Reports",
      menuIcon: PieChart,
      pathOrUrl: "/reports",
      module: ReportsModuleDefinition,
      visibility: (user) => hasRole(user.roles, [...aoRoles, 3]),
      experimentalFeatureVisibleName: "portal-reports",
    },
    {
      menuTitle: "Complaints",
      menuIcon: SmsFailed,
      pathOrUrl: "/complaints",
      module: ComplaintsModuleDefinition,
      visibility: ComplaintsModuleDefinition.visibleOnNavigationBar,
      loadWhenInvisible: () => true,
    },
    {
      menuTitle: "Public enquiries",
      menuIcon: ContactSupport,
      pathOrUrl: "/public-enquiries",
      module: PublicEnquiryModuleDefinition,
      visibility: (user) => user.userType === 1 && hasRole(user.roles, [3, 37]),
      loadWhenInvisible: (_) => false,
      experimentalFeatureVisibleName: "portal-public-enquiry",
    },
    {
      menuTitle: "FOI",
      menuIcon: ContactSupport,
      pathOrUrl: "/foi",
      module: FoiModuleDefinition,
      visibility: (user) => user.userType === 1 && hasRole(user.roles, [3, 38]),
      loadWhenInvisible: (_) => false,
      experimentalFeatureVisibleName: "portal-foi",
    },
    {
      menuTitle: "SARS",
      menuIcon: ContactSupport,
      pathOrUrl: "/sars",
      module: SarsModuleDefinition,
      visibility: (user) => user.userType === 1,
      loadWhenInvisible: (_) => false,
      experimentalFeatureVisibleName: "portal-sars",
    },
    {
      menuTitle: "Links",
      menuIcon: Link,
      pathOrUrl: "/links",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: () => true,
    },
    {
      menuTitle: "Risk",
      menuIcon: InsertChart,
      pathOrUrl: "/svr",
      module: SVRModuleDefinition,
      visibility: SVRModuleDefinition.visibleOnNavigationBar,
      loadWhenInvisible: (user) => user.userType === 1,
    },
    {
      menuTitle: "SoC",
      menuIcon: Domain,
      pathOrUrl: "/soc/2022",
      module: Soc2022ModuleDefinition,
      visibility: Soc2022ModuleDefinition.visibleOnNavigationBar,
      loadWhenInvisible: () => true,
      experimentalFeatureVisibleName: "portal-soc/2022",
    },
    {
      menuTitle: "SoC",
      menuIcon: Domain,
      pathOrUrl: "/soc/2023",
      module: Soc2023ModuleDefinition,
      loadWhenInvisible: () => true,
      visibility: Soc2023ModuleDefinition.visibleOnNavigationBar,
      experimentalFeatureVisibleName: "portal-soc/2023",
    },
    {
      menuTitle: "SoC",
      menuIcon: Domain,
      pathOrUrl: "/soc/2024",
      module: SocV2ModuleDefinition,
      visibility: SocV2ModuleDefinition.visibleOnNavigationBar,
      experimentalFeatureVisibleName: "portal-soc/v2",
    },
    {
      menuTitle: "NOT_NEEDED",
      menuIcon: Domain,
      pathOrUrl: "/support",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: (user) => false,
      loadWhenInvisible: () => true,
    },
    {
      menuTitle: "NOT_NEEDED",
      menuIcon: Domain,
      pathOrUrl: "/eventnotifications",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: () => false,
      loadWhenInvisible: () => true,
      experimentalFeatureHiddenName: "InboxModule",
    },
    {
      menuTitle: "NOT_NEEDED",
      menuIcon: Domain,
      pathOrUrl: "/contactao",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: () => false,
      loadWhenInvisible: () => true,
      experimentalFeatureHiddenName: "InboxModule",
    },
    {
      menuTitle: "NOT_NEEDED",
      menuIcon: Domain,
      pathOrUrl: "/aorequest",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: () => false,
      loadWhenInvisible: () => true,
      experimentalFeatureHiddenName: "InboxModule",
    },
    {
      menuTitle: "NOT_NEEDED",
      menuIcon: Domain,
      pathOrUrl: "/contacttheregulator",
      module: {
        component: () => (
          <Redirect host={process.env.REACT_APP_REDIRECT_HOST || ""} />
        ),
        redux: undefined,
      },
      visibility: () => false,
      loadWhenInvisible: () => true,
      experimentalFeatureHiddenName: "InboxModule",
    },
    {
      menuTitle: "Legal cases",
      menuIcon: Work,
      pathOrUrl: "/lcm",
      module: LcmModuleDefinition,
      visibility: (u) =>
        u.userType === 1 && hasRole(u.roles, [3, 39, 40, 41, 42]),
      loadWhenInvisible: () => false,
      experimentalFeatureVisibleName: "portal-lcm",
    },
    {
      menuTitle: "Specialist",
      menuIcon: School,
      pathOrUrl: "/specialist",
      module: SpecialistModuleDefinition,
      visibility: (u) =>
        u.userType === 1 && hasRole(u.roles, [3, 21, 22, 23]),
      loadWhenInvisible: () => true,
      experimentalFeatureVisibleName: "portal-specialist",
    },
    {
      menuTitle: "Commissions",
      menuIcon: AssignmentInd,
      pathOrUrl: "/commissions",
      module: ExpertCommissionsDefinition,
      visibility: (user) => user.userType === 1 && hasRole(user.roles, [3, 21, 23]),
      loadWhenInvisible: (_) => false,
      experimentalFeatureVisibleName: "portal-commissions",
    },

  ];
  return (
    <Shell
      auth={
        process.env.REACT_APP_AUTH_TYPE === "DEV"
          ? Authentication.localAuthorization
          : Authentication.azureAuthorization
      }
      items={menuItems}
      userDetails={Authentication.UserDetails}
      toolbarItems={[NotificationsModuleDefinition, supportButtonDefinition]}
      coreItems={[ActivtyFeedModuleDefinition, LinkedRecordsModuleDefinition]}
    ></Shell>
  );
}
export default App;
